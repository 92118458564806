<template>
  <div class="container-wrap">
    <Header :navSelIndex="4"></Header>
    <div class="header-con"></div>
    <b-container>

      <div class="inspiration" v-for="(item, index) in masterList[1]" :key="index">
        <div class="inspiration_title">
          <div class="inspiration_txt">
            <span class="name">{{ item.firstTitle }}</span>
            <span class="suitable">{{ item.firstText }}</span>
          </div>
          <!-- <img src="images/super_sale/allroom.png" alt=""> -->
          <div class="all_txt" @click="goClassification(item.sceneId, 'scene')">所有{{ name }}→</div>
        </div>
        <div class="bot_img">
          <div class="scenario">
            <img class="scenario_img" :src="item.coverUri" alt="">
            <div class="point">
              <div @mouseover="mouseOver(index, item)" v-for="(item, index) in JSON.parse(item.content).objects"
                :key="index" :style="{ left: item.left + 4 + 'px', top: item.top + 4 + 'px' }" class="dot" @click="dot(index)"></div>
              <div v-for="(item, index) in JSON.parse(item.content).objects"
                :style="{ left: item.left - 44 + 'px', top: item.top - 44 + 'px' }" class="pulse" :key="index"></div>
              <div v-for="(item, index) in JSON.parse(item.content).objects"
                :style="{ left: item.left - 44 + 'px', top: item.top - 44 + 'px' }" class="pulse1" :key="index"></div>
                <div v-for="(item, index) in JSON.parse(item.content).objects" :key="index">
              <div @click="goDetail" @mouseover="mouseOver(index, item)" @mouseleave="mouseLeave"
                :style="{ left: item.left + 20 + 'px', top: item.top + 20 + 'px' }" class="pro_text" v-if="ind==index">
                  <div class="pro_name">{{ goodsInfo.goods_name }}</div>
                  <div class="pro_price" v-if="item.discountPrice != ''">￥ {{ goodsInfo.discountPrice }}</div>
                </div>
                </div>
            </div>
          </div>
          <div class="right_img">
            <div class="fa_img">
              <img class="left_img" :src="item.labelImg.split(',')[0]" alt="">
              <img :src="item.labelImg.split(',')[1]" alt="">
            </div>
            <div class="fa_img fa_img_bot">
              <img class="left_img" :src="item.labelImg.split(',')[2]" alt="">
              <img :src="item.labelImg.split(',')[3]" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="new_product">
        <img class="new_product_img" src="@/../public/img/｜      Best seller@2x.png" alt="">
        <div class="new_product_list">
          <div class="prod_det" v-for="(item, index) in masterList[2]" :key="index" @click="goGooddetail(item.goodsId)">
            <img :src="item.goodsImg" alt="">
            <span class="title">{{ item.goodsName }}</span>
            <div class="price">
              <span class="symbol">￥</span>
              <span class="num" v-if="item.discountPrice != ''">{{ item.discountPrice }}</span>
              <span class="num" v-if="item.discountPrice == ''">{{ item.salePrice }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="solution">
        <div class="solution_top" v-for="(item, index) in masterList[3]" :key="index">
          <div class="solution_cont">
            <span class="lines"></span>
            <span class="solution_title">{{ item.secondTitle }}</span>
            <div class="solution_details">
              <!-- <span class="solution_txt">小D别出心裁，分享了她精心设计好的，经济实惠的空间饰品搭配方案，在这里你可以找到针对不同主题、不同生活阶段，小D助你一套搞定。</span> -->
              <span class="specific" v-html="item.secondText"></span>
            </div>
            <div class="price" @click="addShopCart(item)">
              <span class="price_txt">一键加入购物车</span>
              <!-- <span class="price_symbol">￥ </span>
              <span class="price_num">{{ item.price }}</span> -->
            </div>
            <span class="lines"></span>
          </div>
          <div class="solution_img">
            <img :src="item.coverUri" alt="">
          </div>
        </div>
        <div class="advance" v-if="masterList[3].length > 1">
          <span @click="soluPerv"></span>
          <span :class="index == solutId ? 'advanShow' : ''" v-for="(item, index) in masterList[3]"
            :key="index">{{ index + 1 }}</span>
          <span @click="soluNext">></span>
        </div>
      </div>

      <!-- <el-pagination
        v-if="totalPage > 0"
        :page-count="totalPage"
        @current-change="changeCurrentPage"
        class="margin-top-80"
        background
        layout="prev, pager, next"
      ></el-pagination> -->
    </b-container>
    <Right></Right>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Right from "@/components/Right.vue";
//   import dump from "@/util/dump";
export default {
  components: {
    Header,
    Footer,
    Right
  },
  created() {
    this.getData();
  },
  data() {
    return {
      token: '',
      id: '',
      masterList: [],
      name: '',
      englishName: '',
      ind: -1,
      goodsInfo: '',
      solutId: 0,
      currentPage: 1,
      totalPage: 5
    }
  },
  methods: {
    getData() {
      let id = window.location.href.split("?")[1].split("=")[1];
      // let params = new URLSearchParams(window.location.search);
      // params = params.substring(1,params.length);
      // var id = params.split("=")[0]
      // console.log("url",params)
      // let id = params.has("id")
      let token = localStorage.getItem("token")
      // console.log("id",id,"token",token)
      this.$api.canvasInfo({
        id: id,
        token: token
      }).then((res) => {
        let params = new URLSearchParams(window.location.hash.split("?")[0]);
        let id = params.get("id")
        console.log("id", id)
        this.masterList = res.data
      })
    },
    goGooddetail(goodsId) {
        window.open("/product/details?good_id=" + goodsId)
      },
    mouseOver(index, item) {
      let _this = this
      _this.ind = index
      this.$api.getGoodsInfo({
        goods_id: item.goodsId,
        token: this.token
      }).then((res) => {
        _this.goodsInfo = res.data
        console.log(res)
      })

    },
    mouseLeave() {
      this.ind = -1
    },
    goDetail() {
      window.open("product/details?good_id=" + this.goodsInfo.goods_id)
    },
    dot(index) {
      this.ind = index
    },

    //   enter(index, id) {
    //     let _this = this
    //     _this.tab = index
    //     _this.navList = []
    //     if (id) {
    //       _this.navLi(id)
    //     }

    // },
    // leave() {
    //   this.tab = -1
    // },
    // navLi(id) {
    //   let _this = this
    //   let urlhll = ''
    //   let parm = {
    //   }
    //   _this.titId = id
    //   if (id == '24') {
    //     urlhll = "/ehome-api-service/appgoodstype/getThirdLabel.do"
    //     parm.label_id = id
    //   }else if(id == '1'){
    //     _this.navList = [
    //       {
    //         third_label_id: 1111,
    //         third_label_name: "所有产品",
    //         url: 'scene_classification.html'
    //       },
    //       {
    //         third_label_id: 9999,
    //         third_label_name: "空间场景",
    //         url: 'scene_classification.html'
    //       },
    //       {
    //         third_label_id: 8888,
    //         third_label_name: "风格类型",
    //         url: 'scene_classification.html'
    //       },

    //       {
    //         third_label_id: 5555,
    //         third_label_name: "花艺搭配",
    //         url: 'scene_classification.html?themeId=92'
    //       },
    //       {
    //         third_label_id: 6666,
    //         third_label_name: "自由E搭配",
    //         url: ''
    //       }
    //     ]
    //     return false;
    //   } else {
    //     urlhll = "/ehome-api-service/appgoods/getIndexStyle.do"
    //     parm.style_type = id
    //   }
    //   $.ajax({
    //     url: urlhead + urlhll,
    //     type: "post",
    //     data: parm,
    //     dataType: "json",
    //     timeout: "5000",
    //     success: function (res) {
    //       _this.navList = res.data
    //       console.log('_this.navList', _this.navList)
    //     }
    //   });
    // }
    //   selectColors(obj) {
    //     console.log(obj)
    //     this.colorsItems.forEach(item => {
    //       item.checked = false;
    //     })
    //     if (obj) {
    //       obj.checked = !obj.checked;
    //       this.colorAll = false;
    //     } else {
    //       this.colorAll = true;
    //     }
    //     // color_id = obj ? obj.LABEL_ID : '';
    //     // currentPage = 1;
    //   },
    //   toProductDetail(productDetail) {
    //     let data = {
    //       type: 3,
    //       link: '/product/details',
    //       params: {
    //         good_id: productDetail.goods_id
    //       }
    //     };
    //     dump.link(data);
    //   },
    //   changeCurrentPage(info) {
    //     this.currentPage = info;
    //     // this.getModeList(info);
    //     this.$utils.scrollTop();
    //   },
    //   //场景分类
    //   getProgramme(){
    //     this.$api.getProgramme().then((res) => {
    //       this.spaceList = res.data;
    //       console.log("sceneList",this.spaceList)
    //     })
    //     this.getCanvasSearch()
    //   },
    //   //场景画布
    //   getCanvasSearch(){
    //     this.$api.canvasSearch({
    //       sceneSpaceId : this.form.space,
    //       priceId: this.form.price
    //     }).then((res) =>{
    //       this.canvasList = res.data.list
    //       console.log(res.data)
    //     })
    //   }
  }
}
</script>

<style lang="scss" scoped>@import "@/assets/scss/style";
@import "with_master";</style>